app.controller('navigationController', ['$location', '$q', '$route', '$rootScope', '$scope', '$window', 'BreadcrumbsService', 'MessagesService', 'TasksService', 'TeamService', 'UsersService','SettingsService','ProxyService', '$timeout',
    function ($location, $q, $route, $rootScope, $scope, $window, BreadcrumbsService, MessagesService, TasksService, TeamService, UsersService, SettingsService, ProxyService, $timeout) {
        var _this = this;
        
        $scope.showLogout = showLogout();

        function showLogout() {
            if (UsersService.roleObj.type === 'student' && $route.current.params.name === 'home' && typeof $route.current.params.param === 'undefined') {
                return false;
            }
            return true;
        }

        $scope.logoUrl = "app/images/BAI_HorizontalLockup_Primary_CMYK.png";
        var customLogoUrl = UsersService.customLogoUrl();
        if (typeof customLogoUrl !== 'undefined' && customLogoUrl !== null) {
            $scope.logoUrl = customLogoUrl;
        }

        //// added to show the IE message on student home page 
        var homeRole = UsersService.homeRole;
        if (homeRole === "student" && $location.path() === '/users/home/') {
            UsersService.navigateToHome();
        }

        /**
         * Keep _this and $scope so we can trigger scope change one all calls are finished.
         *
         * @type {int}
         */
        _this.messageCount = 0;
        $scope.messageCount = 0;

        $scope.goHome = function (nextUrl) {
            var homeRole = UsersService.homeRole;
            var context = UsersService.setUserContext(homeRole);
            context.then(function (success) {
                UsersService.navigateToHome();
                if (nextUrl) { // If we can't use href in combination with goHome
                    $timeout(function() {
                        $scope.goToRoute(nextUrl);
                    });
                }
            });
        };

        $scope.goToBreadcrumbHome = function (nextUrl) {
            var homeRole = UsersService.homeRole;
            var context = UsersService.setUserContext(homeRole);
            context.then(function (success) {
                if (homeRole === 'decisionMaker') {
                    $location.url('insights/home');
                } else {
                    if (homeRole === 'ta') {
                        UsersService.navigateToHome();
                    } else {
                        $location.url('users/home');
                    }
                }
                if (nextUrl) { // If we can't use href in combination with goHome
                    $timeout(function() {
                        $scope.goToRoute(nextUrl);
                    });
                }
            });
        };

        $scope.goToRoute = function(route) {
            $location.url(route);
        };

        /**
         * Due to lack of way to get a group of promises to not fail if one/many of the calls fails, we must do it the old
         * fashioned way with a counter. There is a 3rd party lib, but we will not be implementing that until hardening
         * period, potentially after that (use https://github.com/ohjames/angular-promise-extras).
         *
         * Until then, we will keep track of calls. There will always be at least one call; there will be two if the
         * user is currently in the admin role.
         */
        _this.messageBubbleCompletedRequestTotal = 0; // total number of completed calls
        _this.messageBubbleTotalPossibleRequests = 1; // total number of calls to be made

        var message = MessagesService.fetch();
        message.then(function (success) {
            // call completed; increment
            _this.messageBubbleCompletedRequestTotal++;

            /**
             * This is for the toaster bubbles. This is currently disabled in the view.
             */
            $scope.messages = success;
            $scope.tooMany = false;

            _this.messageCount += $scope.messages.length;

            if ($scope.messageCount > 2) {
                var messages = [];

                messages.push($scope.messages[0]);

                $scope.messages = messages;
                $scope.tooMany = true;
            }

            _this.showNotifications();
        }, function (failure) {
            // call completed; increment
            _this.messageBubbleCompletedRequestTotal++;

            _this.showNotifications();
        });

        /**
         * Only run if the user is an admin, as the API will fail for 1/2 of the personas, thus wasting a call.
         */
        if (UsersService.isAdmin() === true) {
            // add numer of total calls that exist
            _this.messageBubbleTotalPossibleRequests += 2;

            /**
             * Get all the approval requests.
             */
            var requests = TeamService.getListOfAssignmentsToApprove();
            requests.then(function (success) {
                _this.messageBubbleCompletedRequestTotal++;

                _this.messageCount += success.length;

                _this.showNotifications();
            }, function (failure) {
                // call completed; increment
                _this.messageBubbleCompletedRequestTotal++;

                _this.showNotifications();
            });

            /**
             * Get all the task approvals
             */
            var tasks = TasksService.getTasksApprovalTA();
            tasks.then(function (success) {
                _this.messageBubbleCompletedRequestTotal++;

                _this.messageCount += success.length;

                _this.showNotifications();
            }, function (failure) {
                // call completed; increment
                _this.messageBubbleCompletedRequestTotal++;

                _this.showNotifications();
            });
        }

        /**
         * This method has to run on every completion. Sometimes, AJAX can go failure, success, failure. That means we won't
         * get proper triggering. This method will run on all failures and successes, so eventually n will equal n, which
         * means we will be able render the message properly.
         */
        _this.showNotifications = function () {
            if (_this.messageBubbleCompletedRequestTotal === _this.messageBubbleTotalPossibleRequests) {
                $scope.messageCount = _this.messageCount;
            }
        };



        $scope.loadSettings = function () {

            SettingsService.getSettings().then(function (success) {
                /* Proxy Mode determined by the organization settings */
                $scope.showProxyMode = SettingsService.isProxyModeEnabled();
            });
        };

        $scope.hasAccess = false;
        $scope.getUrlsForRoles = UsersService.getUrlsForRoles();
        $scope.getProxyAccess = function () {
            var acess = ProxyService.getUrlsForProxy();
            acess.then(function (success) {
                $scope.hasAccess = success.length > 0 ? true : false;
            }, function (failure) {
            });
        };
        $scope.getProxyAccess();
        $scope.isSabaUser = UsersService.isSaba();
        $scope.hasStudentRole = _.findWhere($scope.getUrlsForRoles, {roleName: "student"}) ? true : false;
        $scope.hasManagerRole = _.findWhere($scope.getUrlsForRoles, {roleName: "manager"}) ? true : false;
        $scope.hasInstructorRole = _.findWhere($scope.getUrlsForRoles, {roleName: "instructor"}) ? true : false;
        $scope.hasAdminRole = _.findWhere($scope.getUrlsForRoles, { roleName: "admin" }) || _.findWhere($scope.getUrlsForRoles, { roleName: "ta" }) ? true : false;
        $scope.hasProxyModeRole = _.findWhere($scope.getUrlsForRoles, { roleName: "ta" }) || _.findWhere($scope.getUrlsForRoles, { roleName: "manager" }) ? true : false;
        $scope.hasDecisionMakerRole = _.findWhere($scope.getUrlsForRoles, { roleName: "decisionMaker" }) ? true : false;
        $scope.navLinks = UsersService.getNavigation();
        $scope.user = UsersService.user;
        $scope.impersonate = UsersService.impersonate;
        
        $scope.openSideNav = function () {
            jQuery('#flyout').slideToggle();
        };

        $scope.closeMessagePopup = function ($event) {
            var clicked = jQuery($event.currentTarget);
            clicked.parents('.message').fadeOut(500);
        };

        $scope.revokeImpersonation = function () {
            var scoperole = "";

            if($scope.impersonate.viewType !== '' && $scope.impersonate.viewType > 0)
            {
                var proxy = UsersService.revokeImpersonation();

                proxy.then(function () {             
                    $scope.getUrlsForRoles = UsersService.getUrlsForRoles();
                    for (var i = 0; i < $scope.getUrlsForRoles.length; i++) {
                        if ($scope.getUrlsForRoles[i].roleName === 'ta') {
                            scoperole = 'ta';
                            break;
                        }
                        if ($scope.getUrlsForRoles[i].roleName === 'manager') {
                            scoperole = 'manager';
                            break;
                        }
                    }
                    //console.log(scoperole);
                    if (scoperole === 'ta' || scoperole === 'manager') {
                        var admincontext = UsersService.setUserContext(scoperole);
                        admincontext.then(function () {
                            $location.url('proxy/home/');
                        });
                    } else {                     
                        var studentcontext = UsersService.setUserContext('student');
                        studentcontext.then(function () {
                            $location.url('proxy/home/');
                        });
                    }
                });
            }
        };

        $scope.logout = function () {
            var portalCode = UsersService.getPortalCode();
            var logout = UsersService.clearSession();
            logout.then(function (success) {

                // If a user's portal code existed while being logged in, persist it on the logged out state.
                if (portalCode !== '' && portalCode !== null) {
                    // alert('logging out with portalCode');
                    portalCode = '?portal=' + portalCode;
                }

                $location.url('/' + portalCode);
            }, function (failure) {

            });

        };

        $scope.changeUserScope = function (scope, uri) {

            if (uri === 'users/transcripts/') {
                $location.url('student/transcripts/');
                UsersService.goingToTranscript = true;
            }
            /**
             * Since we may be waiting for an API call, we have to wait for it to finish. Does not
             * affect non-api calls.
             */
            var context = UsersService.setUserContext(scope);

            context.then(function () {
                if (scope === 'decisionMaker') {
                    $location.url('insights/home');
                } else if (scope === 'catalogLibrary') {
                    $location.url('catalog/');
                } else {
                    if (typeof uri !== 'undefined' && uri !== null) {
                        $location.url(uri);
                    } else if ($location.url() === '/users/home/') {
                        $location.url(''); // EA-14021 to force a rerender when it navigates back to /users/home (in case if user toggles between Administration and Team Management)
                        $scope.openSideNav(); // closes menu
                        if (scope === 'ta') {
                            UsersService.navigateToHome();
                        }
                    } else if (scope === 'ta') {
                        UsersService.navigateToHome();
                    } else {
                        $location.url('users/home');
                    }
                }
            });
        };

        $scope.changeUserScopeForProxy = function () {
            var scoperole = "";

            if ($scope.hasAdminRole === true) {
                scoperole = 'ta';
            } else if ($scope.hasManagerRole === true) {
                scoperole = 'manager';
            }

            if ($scope.hasProxyModeRole  === true) {
                var context = UsersService.setUserContext(scoperole);

                context.then(function () {
                    $location.url('proxy/home/');
                });
            }
            else if ($scope.hasAccess === true) {
                $location.url('proxy/home/');
            }            
        };

        var toggles = document.querySelectorAll(".c-hamburger");

        for (var i = toggles.length - 1; i >= 0; i--) {
            var toggle = toggles[i];
            toggleHandler(toggle);
        }

        function toggleHandler(toggle) {
            toggle.addEventListener("click", function (e) {
                e.preventDefault();
                var active = (this.classList.contains("is-active") === true) ? this.classList.remove("is-active") : this.classList.add("is-active");
            });
        }

        // Breadcrumbs are generated by individual services.
        // Put this in a watch function because sometimes crumbs
        // are updated in ajax calls.

        $scope.$watch(function () {
            return BreadcrumbsService.getCrumbs();
        }, function (newVal, oldVal) {
            $scope.breadcrumbs = newVal;
        }, true);

        $scope.$watch('$viewContentLoaded',
            function () {
                $timeout(function () {
                    $scope.loadSettings();
                }, 1000);
            });

        /**
         * Subcriber to the publisher that exists in the messages/admin controller. Only way to trigger a re-calculation
         * of the total number of requests without doing a new call.
         */
        $scope.$on('requestApprovals:action:success', function () {
            // Ensure that there will be at least request after subtracting one.
            if ($scope.messageCount - 1 > 0) {
                $scope.messageCount -= 1;
            } else {
                $scope.messageCount = 0;
            }
        });
    }
]);